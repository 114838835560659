import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BannerRussel from '../../../../components/BannerRussel';
import BackGlossary from '../../../../components/BackGlossary';

const ZspZeroStandingPrivilege = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `ZSP`;
  const description = `Zero Standing Privilege`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-8 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className="max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <div className="md:px-5 max-w-[1285px]">
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Zero Standing Privilege (ZSP)</div>
                <p className="text-lg text-left">
                  Whereas to have “standing privilege” means to always have access to a resource (whether they need or
                  are using them), Zero Standing Privilege (ZSP) describes an identity’s state of having none, or zero
                  access to a resource. <br></br>
                  <br></br>
                  <b>Why is this an important concept?</b>
                  <br></br> Think of it like this: if an identity has a standing admin / root access to a production
                  system, that means the entity assuming that identity can perform admin / root operations against that
                  production system.<br></br>
                  <br></br> This is fine when that entity is your IT staff doing their jobs, but is really bad if its a
                  bad actor who has managed to hack their way in.<br></br>
                  <br></br> In the wrong hands, an identity with standing privileged access is a serious threat. But if
                  an identity has zero standing access, with no access permissions bound to it, it posesses no threat at
                  all.
                </p>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      <section className="pb-16">
        <BannerRussel
          title="Wondering how Trustle works?"
          description={'Get an inside look at Trustle. Schedule a demo with us today.'}
        />
      </section>
    </Layout>
  );
};

export default ZspZeroStandingPrivilege;
